.mapcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 247, 244, 0.3);
  padding: 3rem 1rem 1rem 1rem;
  overflow: hidden;
}

.buttonsContainer {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.zoomButton {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}

.mapContainer {
  position: relative;
  width: 95%;
  height: 80%;
  max-height: 73vh;
  /* border: 1px solid #ccc; */
  overflow: scroll;
  background-color: white;
  border-radius: 10px;
}

.mapImage {
  width: 100%;
  height: auto;
}

@media (max-width: 600px) {
  .mapContainer {
    height: 30vh;
  }
}
