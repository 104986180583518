.slideshow {
  position: relative;
  width: 100%;
  height: 42vw;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.slideshow-image {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
}

.darkened-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity for desired darkness */
  z-index: 1;
}

.slideshow-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #fff;
  text-align: center;
  justify-content: center;
}

.welcome-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.main-line {
  font-size: 2.5rem;
  margin: 10px;
}

.welcome-line {
  font-size: 1.8rem;
  margin: 10px;
}

.dci-line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.line {
  height: 1.5px;
  width: 25vw;
  background-color: #fff;
}

@media (max-width: 600px) {
  .slideshow {
    height: 90vh;
  }
  .main-line {
    font-size: 1.8rem;
  }
  .welcome-line {
    font-size: 1.3rem;
  }

  .welcome-message {
    height: 60vw;
  }
  .line {
    height: 1px;
  }
}
