.dual-container {
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
  width: 98%;
  margin: auto;
}

.rooms-container {
  padding-top: 20px;
  background-color: #fffaf1;
}

.carousel-wrapper {
  flex: 1;
  margin: 10px;
}
