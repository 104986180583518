.header {
  position: sticky; /* Keep the header fixed at the top */
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: #fffefc;
  transition: padding 0.3s ease, height 0.3s ease;
  z-index: 1000; /* Ensure it stays on top of other elements */
}

.header-small {
  padding: 5px 20px; /* Reduced padding when scrolled */
  height: 50px; /* Reduced height when scrolled */
}

.header-logo img {
  height: 70px;
  transition: height 0.3s ease;
  cursor: pointer;
}

.header-small .header-logo img {
  height: 50px; /* Reduced logo height when scrolled */
}

.header-small .logo-caption {
  display: none; /* Hide the caption when scrolled */
}

.header-small .header-button {
  font-size: 1rem;
}

.logo-caption {
  color: #282c34;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.header-buttons {
  display: flex;
  gap: 30px;
  margin-right: 30px;
}

.header-button {
  padding: 10px 10px;
  font-size: 1.8rem;
  color: #fdf9f5;
  background: linear-gradient(145deg, #ffb453, #fa9716);
  border: 2px solid #ffa32b;
  border-radius: 16px;
  cursor: pointer;
  transition: font-size 0.3s ease, padding 0.3s ease;
}

.header-button:hover {
  background: linear-gradient(145deg, #ffd49d, #ffc982);
  color: #945400;
}

@media (max-width: 600px) {
  .header {
    padding: 10px 20px;
  }

  .header-small {
    padding: 5px 10px;
  }

  .header-logo img {
    height: 50px;
  }

  .header-small .header-logo img {
    height: 40px;
  }

  .header-button {
    padding: 8px 8px;
    font-size: 0.9rem;
  }

  .header-buttons {
    gap: 14px;
    margin-right: 0px;
  }

  .logo-caption {
    display: none;
  }
}
