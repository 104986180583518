.container {
  max-width: 600px;
  height: auto;
  width: 92%;
  margin: auto;
  text-align: center;
  padding: 10px;
  border: 1px solid #ece2ce;
  border-radius: 10px;
  background-color: #fdfcfb;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Added drop shadow */
}

.title {
  /* font-size: 2em; */
  margin: 20px 0;
}

.description {
  font-size: 1.2em;
  margin-top: 20px;
  text-align: left;
}

.carousel-image {
  width: 300px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.carousel .control-arrow {
  width: 80px; /* Adjust this value to make the arrows wider */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .dual-container {
    flex-direction: column;
    align-items: center;
  }

  .carousel-wrapper {
    margin: 20px 0;
    max-width: 90%; /* Adjust width for better display on mobile */
  }
}
