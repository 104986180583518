.footer {
  text-align: center;
  padding: 2rem 3rem 2rem 3rem;
  background-color: #252525;
  color: #efefef;
}

.footer-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  margin-top: 0;
}

.footer-divider {
  width: 100%;
  border: 2px solid #efefef;
  margin: 0.5rem auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.footer-contact {
  flex: 1;
  margin: auto;
  text-align: right;
}

.footer-address {
  flex: 1;
  margin: auto;
  text-align: left;
}

.footer-para {
  margin: 0.5rem 0;
}

.footer-addr-link {
  color: #efefef;
  text-decoration: none;
}

.footer-bottom {
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgb(223, 223, 223);
}

.bottom-p {
  margin: 1.2rem 0 0 0;
}

.name-link {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.name-link:hover {
  color: #945400;
}

@media screen and (max-width: 600px) {
  .footer {
    padding: 2rem 1rem;
  }
  .footer-content {
    flex-direction: column;
  }

  .footer-contact {
    text-align: left;
    margin-left: 5px;
  }

  .footer-address {
    text-align: left;
    margin-left: 5px;
  }
}
