/* src/InfoText1.css */
.info-text2 {
  padding: 30px 0;
  background-color: #fcf2de;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.image2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 50px;
}

.text2 {
  z-index: 1;
  width: 50%;
  padding: 50px 20px 50px 100px;
  text-align: justify;
  color: #4d3737;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .info-text2 {
    flex-direction: column;
    padding: 30px 0 0 0;
  }

  .text2 {
    padding: 20px;
    width: 85%;
    font-size: 1rem;
  }

  .image2 {
    width: 85%;
    padding: 0 20px 30px 20px;
  }
}

/* @media (max-width: 480px) {
    .info-text2 {
      flex-direction: column;
      padding: 30px 0 0 0;
    }
    .text2 {
      padding: 20px;
      width: 85%;
      font-size: 1rem;
    }
  
    .image2 {
      width: 85%;
      padding: 0 20px;
    }
  } */
