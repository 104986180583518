/* src/InfoText1.css */
.info-text3 {
  padding: 30px 0;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

/* .image1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 50px;
} */

.text3 {
  z-index: 1;
  width: 70%;
  padding: 50px 20px 50px 100px;
  text-align: center;
  color: #4d3737;
  font-size: 1.8rem;
}

@media (max-width: 768px) {
  .info-text3 {
    flex-direction: column;
    padding: 30px 0 0 0;
  }

  .text3 {
    padding: 20px;
    width: 85%;
    font-size: 1.2rem;
  }

  .image1 {
    width: 85%;
    padding: 0 20px;
  }
}

/* @media (max-width: 480px) {
      .info-text3 {
        flex-direction: column;
        padding: 30px 0 0 0;
      }
      .text3 {
        padding: 20px;
        width: 85%;
        font-size: 1rem;
      }
    
      .image1 {
        width: 85%;
        padding: 0 20px;
      }
    } */
